import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import type { ShortIssueWithId } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import type { UpdatedIssue } from '~/components/Issue/Table/types'
import { IssueBuyUrlTypeEnum } from '~/components/Issue/types'
import { BuyIssueModalCloseEnum } from '~/composables/useDataLayer'
import type { DataLayerLocationsEnum } from '~/composables/useDataLayer'
import type { MaybeDeepReadonly } from '~/types/helpers'

export function useHandleBuyIssueModalCloseSendEvent() {
  function handleBuyIssueModalCloseSendEvent(
    closeType: 'backdrop' | 'button' | 'custom',
    issue: MaybeDeepReadonly<IssueDetails | UpdatedIssue | ShortIssueWithId>,
    modalType: IssueBuyUrlTypeEnum,
    location: DataLayerLocationsEnum.IssueDetails | DataLayerLocationsEnum.IssuesList | DataLayerLocationsEnum.Floating,
  ) {
    const { onSrovAffiliateRedirectModalClose, onSrovOrderOnlineModalClose } = useDataLayer()

    let type = BuyIssueModalCloseEnum.Curtain

    switch (closeType) {
      case 'backdrop':
        type = BuyIssueModalCloseEnum.Curtain
        break

      case 'button': // click on X
        type = BuyIssueModalCloseEnum.Close
        break

      case 'custom': // click on button
        type = BuyIssueModalCloseEnum.Button
        break

      default:
        break
    }

    if (modalType === IssueBuyUrlTypeEnum.JAMES) {
      onSrovOrderOnlineModalClose(issue, location, type)
    }
    else {
      onSrovAffiliateRedirectModalClose(issue, location, type)
    }
  }

  return { handleBuyIssueModalCloseSendEvent }
}

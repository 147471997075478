import type { IssueDetails } from '~/api/modules/issues/services/getIssueDetails/getIssueDetails.types'
import type { ShortIssueWithId } from '~/api/modules/issues/services/getIssuesList/getIssuesList.types'
import type { UpdatedIssue } from '~/components/Issue/Table/types'
import { IssueBuyUrlTypeEnum } from '~/components/Issue/types'
import type { IssueBuyEmit } from '~/components/Issue/types'
import { useHandleBuyIssueModalCloseSendEvent } from '~/composables/issues/useHandleBuyIssueModalCloseSendEvent'
import type { MaybeDeepReadonly } from '~/types/helpers'

const animationCloseTime = 300

type closeType = 'backdrop' | 'button' | 'custom'

export function useIssueBuy() {
  const { onSrovAffiliateRedirectClick, onBuyNowClickEvent } = useDataLayer()

  const issueToBuy = ref<IssueDetails | UpdatedIssue | ShortIssueWithId | null>(null)

  const orderUrl = ref<string | null>(null)

  const isIssueBuyModalOpen = ref(false)

  const issueBuyModalType = ref<IssueBuyUrlTypeEnum | null>(null)

  function openIssueBuyModal(data: IssueBuyEmit) {
    if (!data.issue || !data.url) {
      return
    }

    issueToBuy.value = data.issue
    orderUrl.value = data.url.orderUrl
    issueBuyModalType.value = data.url.urlType
    isIssueBuyModalOpen.value = true
  }

  function closeIssueBuyModal() {
    isIssueBuyModalOpen.value = false

    // Reset values after close animation
    setTimeout(() => {
      issueToBuy.value = null
      orderUrl.value = null
    }, animationCloseTime)
  }

  function handleBuyNowEventClick(issueToBuy: MaybeDeepReadonly<UpdatedIssue | IssueDetails | ShortIssueWithId>) {
    if (!issueToBuy) {
      return
    }

    closeIssueBuyModal()

    switch (issueBuyModalType.value) {
      case IssueBuyUrlTypeEnum.AFFILIATE:
        onSrovAffiliateRedirectClick(issueToBuy, DataLayerLocationsEnum.IssuesList)
        break

      case IssueBuyUrlTypeEnum.JAMES:
        onBuyNowClickEvent(issueToBuy, DataLayerLocationsEnum.IssuesList)
        break

      default:
        break
    }
  }

  function handleBuyIssueModalClose(closeType: closeType, issueToBuy: MaybeDeepReadonly<UpdatedIssue | IssueDetails | ShortIssueWithId>) {
    if (!issueToBuy || !issueBuyModalType.value) {
      return
    }

    useHandleBuyIssueModalCloseSendEvent().handleBuyIssueModalCloseSendEvent(
      closeType,
      issueToBuy,
      issueBuyModalType.value,
      DataLayerLocationsEnum.IssueDetails,
    )

    closeIssueBuyModal()
  }

  return {
    issueToBuy: readonly(issueToBuy),
    orderUrl: readonly(orderUrl),
    issueBuyModalType: readonly(issueBuyModalType),
    isIssueBuyModalOpen: readonly(isIssueBuyModalOpen),
    openIssueBuyModal,
    closeIssueBuyModal,
    handleBuyNowEventClick,
    handleBuyIssueModalClose,
  }
}
